import { ChangeEvent, ReactNode, useCallback, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import { Input } from '../Input'

export interface PercentageInputProps {
  id: string
  placeholder?: string
  min?: number
  max?: number
  disabled?: boolean
  error?: string
  className?: string
  innerSuffix?: string
  isNumber?: boolean
  formatValue?: boolean
  label?: ReactNode
}

export const PercentageInput = ({
  id,
  placeholder,
  min = 0,
  max = 100,
  disabled,
  error,
  className,
  innerSuffix,
  isNumber,
  formatValue,
  label,
}: PercentageInputProps) => {
  const { setValue, watch } = useFormContext()
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const value = formatValue ? getDisplayedValue(watch(id), isFocused) : watch(id)

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value

      const regex = /^[0-9]+(\.[0-9]*)?$/
      if (!regex.test(newValue) && newValue) return

      if (!isFloat(newValue)) {
        setValue(id, newValue, { shouldValidate: true })
        return
      }

      const newValueClamped = clamp(Number(newValue), min, max)
      setValue(id, isNumber ? newValueClamped : newValueClamped.toString(), { shouldValidate: true })
    },
    [min, max, setValue, id, isNumber],
  )

  return (
    <StyledInput
      id={id}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
      error={error}
      inputMode="numeric"
      className={className}
      innerSuffix={innerSuffix ?? '%'}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      label={label}
    />
  )
}

const getDisplayedValue = (inputValue: number, isFocused: boolean) => {
  if (isFocused) return inputValue

  return inputValue !== 0 && inputValue < 1000
    ? inputValue.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : inputValue.toLocaleString('en-US')
}

const clamp = (value: number, min: number, max: number) => Math.min(max, Math.max(min, value))

const isFloat = (value: string) => /^-?\d+(\.\d+)?$/.test(value)

export const StyledInput = styled(Input)`
  border-color: ${({ error, disabled, theme }) => (error && !disabled ? theme.colors.PersianRed : theme.colors.Iron)};
`
