import { useMutation } from '@tanstack/react-query'
import { ClientSideBackendError } from 'providers/CustomQueryClientProvider'

export type MutationMethod = 'POST' | 'PUT' | 'DELETE'

export interface Options {
  headers?: Record<string, unknown>
}

export function useBackendMutation<TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
  url: string,
  method: MutationMethod,
  options?: Options,
) {
  const { headers } = options || {}

  return useMutation<TData, TError, TVariables, TContext>({
    mutationFn: async (body: TVariables) => {
      const response = await fetch(url, {
        method,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          ...headers,
        },
        body: JSON.stringify(body ?? {}),
      })
      const responseText = await response.text()
      if (!response.ok) {
        throw new ClientSideBackendError('modifying data in database', responseText)
      }

      if (!responseText) {
        return undefined
      }

      return JSON.parse(responseText)
    },
  })
}
