import { MutationMethod, Options, useBackendMutation } from './useBackendMutation'
import { getApiURL } from 'utils/getApiURL'

export function useAIPBackendMutation<Body, Response = unknown>(
  url: string,
  method: MutationMethod,
  options?: Options,
) {
  return useBackendMutation<Response, unknown, Body>(getApiURL(url), method, options)
}
