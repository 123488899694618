import { ReactNode, useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import { Slider } from 'components/atoms/Slider'
import { PercentageInput } from 'components/atoms/PercentageInput'
import { useFormError } from 'hooks'
import { Column } from 'components/atoms/Column'
import { InputLabel } from 'components/atoms/Input/InputLabel'
import { InputErrorWrapper } from '../InputErrorWrapper'
import { FormDescriptionText } from 'components/atoms/Form'

export interface SliderInputProps {
  id: string
  label?: ReactNode
  placeholder?: string
  min?: number
  max?: number
  sliderMax?: number
  step?: number
  disabled?: boolean
  error?: string
  isRequired?: boolean
  withRange?: boolean
  description?: string | ReactNode
  innerSuffix?: string
  sliderValue?: string
  formatValue?: boolean
  onSliderValueChange?: (values: number[]) => number[]
}

export const SliderInput = ({
  id,
  label,
  sliderMax,
  max = 100,
  min = 0,
  step,
  placeholder,
  disabled,
  isRequired,
  withRange,
  description,
  innerSuffix,
  sliderValue,
  formatValue,
  onSliderValueChange = (val) => val,
}: SliderInputProps) => {
  const minValueId = `${id}.min`
  const maxValueId = withRange ? `${id}.max` : id
  const minValueError = useFormError(minValueId)
  const maxValueError = useFormError(maxValueId)

  const {
    watch,
    setValue,
    formState: { isSubmitting },
  } = useFormContext()
  const disabledSlider = disabled || isSubmitting

  const value = withRange ? [watch(minValueId), watch(maxValueId)] : [watch(maxValueId)]

  const updateValues = useCallback(
    (newValues?: number[]) => {
      if (withRange) {
        setValue(id, { min: newValues?.[0], max: newValues?.[1] }, { shouldValidate: true })
        return
      }
      setValue(id, newValues?.[0], { shouldValidate: true })
    },
    [id, withRange, setValue],
  )
  const sliderMaxValue = sliderMax ?? max

  return (
    <InputErrorWrapper error={minValueError || maxValueError}>
      <Wrapper>
        {withRange && (
          <SliderPercentageInput
            id={minValueId}
            placeholder={placeholder}
            min={min}
            max={max}
            disabled={disabledSlider}
            error={minValueError}
            isNumber
          />
        )}
        {label && (
          <SliderLabel disabled={disabledSlider} isRequired={isRequired}>
            {label}
          </SliderLabel>
        )}
        <StyledInputWrapper id={id}>
          <Slider
            max={sliderMaxValue}
            min={min}
            step={step}
            value={value.map((val) => (max && val > sliderMaxValue ? sliderMaxValue : val))}
            setValue={(values) => updateValues(onSliderValueChange(values))}
            withRange={withRange}
            disabled={disabledSlider}
          />
          {sliderValue && <SliderValue>{sliderValue}</SliderValue>}
        </StyledInputWrapper>
        <SliderPercentageInput
          id={maxValueId}
          placeholder={placeholder}
          min={min}
          max={max}
          disabled={disabledSlider}
          error={maxValueError}
          innerSuffix={innerSuffix ?? '%'}
          formatValue={formatValue}
          isNumber
        />
      </Wrapper>
      {description && (
        <FormDescriptionText variant="body2" color="light">
          {description}
        </FormDescriptionText>
      )}
    </InputErrorWrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  column-gap: 16px;
  margin-top: 10px;
`

export const StyledInputWrapper = styled(Column)`
  row-gap: 16px;
  flex: 1;
  position: relative;
`

const SliderLabel = styled(InputLabel)`
  left: 0;
  color: ${({ theme }) => theme.colors.Corduroy};
  transform: none;
`

const SliderValue = styled.span`
  position: absolute;
  top: 15px;
  right: 0;
  color: ${({ theme }) => theme.colors.Edward};
  font-size: 12px;
  font-weight: 500;
`

const SliderPercentageInput = styled(PercentageInput)`
  max-width: 120px;
  border-color: ${({ error, disabled, theme }) => (error && !disabled ? theme.colors.PersianRed : theme.colors.Iron)};

  ${StyledInputWrapper} {
    max-width: 120px;
  }

  @media ${({ theme }) => theme.queries.mobileAndSmaller} {
    max-width: 80px;
  }
`
