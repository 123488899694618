import * as RadixSlider from '@radix-ui/react-slider'
import styled from 'styled-components'
import { Transitions } from 'styles'

export interface Props {
  value: number[]
  setValue: (value: number[]) => void
  className?: string
  disabled?: boolean
  max?: number
  min?: number
  step?: number
  defaultValue?: number[]
  withRange?: boolean
}

export const Slider = ({ value, setValue, defaultValue, withRange, ...props }: Props) => {
  const handleChange = (value: number[]) => {
    setValue(value)
  }

  return (
    <Root value={value} onValueChange={handleChange} defaultValue={defaultValue} minStepsBetweenThumbs={1} {...props}>
      <Track>
        <Range />
      </Track>
      <Thumb />
      {withRange && <Thumb />}
    </Root>
  )
}

const Thumb = styled(RadixSlider.Thumb)`
  all: unset;
  display: block;
  width: 16px;
  height: 16px;
  background-color: ${({ theme }) => theme.colors.CopperOrange};
  border: 2px solid ${({ theme }) => theme.colors.White};
  border-radius: 16px;
  transition: ${Transitions.all};

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.CopperOrange};
  }
  &:focus-visible {
    background-color: ${({ theme }) => theme.colors.CopperOrange};
  }
`

const Range = styled(RadixSlider.Range)`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.CopperOrange};
  height: 100%;
`

const Root = styled(RadixSlider.Root)<Pick<Props, 'disabled'>>`
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  width: 100%;
  height: 16px;

  ${Thumb} {
    background-color: ${({ theme, disabled }) => disabled && theme.colors.Iron};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }

  ${Range} {
    background-color: ${({ theme, disabled }) => disabled && theme.colors.Iron};
  }
`

const Track = styled(RadixSlider.Track)`
  background-color: ${({ theme }) => theme.colors.Mouse};
  position: relative;
  flex-grow: 1;
  height: 2px;
`
